import logoHome from "../assets/images/logo.gif";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <motion.div
      className="flex justify-center items-center h-[90dvh]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Link to={"/brand"} className="lg:h-4/6">
        <img src={logoHome} className="h-full w-full" alt="Logo home" />
      </Link>
    </motion.div>
  );
}
