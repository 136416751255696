import linkedinIcon from "../assets/images/linkedin.svg";
import instaIcon from "../assets/images/insta.svg";
import brand1 from "../assets/images/brand-1.png";
import brand2 from "../assets/images/brand-2.png";
import brand3 from "../assets/images/brand-3.png";
import brand4 from "../assets/images/brand-4.png";
import brand5 from "../assets/images/brand-5.png";
import brand6 from "../assets/images/brand-6.png";
import brand7 from "../assets/images/brand-7.png";
import hero from "../assets/images/Duduv2.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function About() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="py-6 px-6 lg:px-0 flex container mx-auto">
        <div className="lg:w-1/2 flex justify-end">
          <div className="lg:w-4/5">
            <div className="flex justify-between items-end">
              <div>
                <h2 className="text-2xl font-medium text-[#1ECB1B]">
                  Designer
                </h2>
                <h2 className="text-2xl font-light">London, UK</h2>
              </div>
              <div className="lg:hidden">
                <img src={hero} className="h-56" alt="Marcel Denize" />
              </div>
            </div>

            <div className="flex flex-col gap-6 mt-6 text-lg font-light">
              <p>
                I’m Marcel, a design creative specialising in brand identity,
                digital and UI design.
              </p>
              <p>
                My passion for ideas, problem-solving and the ability to think
                critically drives me towards great business outcomes. Working
                alongside developers, guiding creative teams, and collaborating
                with external partners has enabled me to cultivate strong visual
                communication, design execution and project management skills. I
                have developed an understanding and appreciation of functional
                design whilst retaining creative play through space, shape and
                colour. In my free time I enjoy visual arts, exploring the
                outdoors, exercising and tennis.
              </p>
              <p>Thanks for stopping by!</p>
            </div>

            <div className="flex flex-row gap-2 mt-4 items-center">
              <Link
                to={"https://www.linkedin.com/in/marcel-denize-02a64942/"}
                target="_blank"
              >
                <img src={linkedinIcon} className="h-10" alt="Linkedin Icon" />
              </Link>
              <Link
                to={"https://www.instagram.com/majopade.design/"}
                target="_blank"
              >
                <img src={instaIcon} className="h-10" alt="Instagram icon" />
              </Link>
              <h3
                className="cursor-pointer text-xl md:text-xl md:m-4"
                onClick={() =>
                  (window.location = "mailto:marceldenize@gmail.com")
                }
              >
                marceldenize@gmail.com
              </h3>
            </div>
          </div>
        </div>
        <div className="hidden lg:w-[40%] lg:block lg:mx-32">
          <img src={hero} className="hero-img" alt="Marcel Denize" />
        </div>
      </div>
      <div className="grid grid-cols-3 px-6 gap-x-4 md:grid-cols-7 md:px-0">
        <div className="h-32">
          <img src={brand7} className="hero-img" alt="NZ Wine logo" />
        </div>
        <div className="h-32">
          <img src={brand1} className="hero-img" alt="Enviro NZ logo" />
        </div>
        <div className="h-32">
          <img src={brand3} className="hero-img" alt="Rogue logo" />
        </div>
        <div className="h-32">
          <img src={brand4} className="hero-img" alt="JS Meditation logo" />
        </div>
        <div className="h-32">
          <img src={brand5} className="hero-img" alt="Sauvignon Blanc logo" />
        </div>
        <div className="h-32">
          <img src={brand6} className="hero-img" alt="Pinot Noir logo" />
        </div>
        <div className="h-32">
          <img src={brand2} className="hero-img" alt="Davis Gardens logo" />
        </div>
      </div>
    </motion.div>
  );
}
