import React, { useState } from "react";
import { motion } from "framer-motion";
import { getPageColor } from "../functions";
import { useLocation } from "react-router-dom";
import LeftArrowButton from "./LeftArrowButton";
import RightArrowButton from "./RightArrowButton";

export default function Slider_v2({ projects = [] }) {
  const [projectIndex, setProjectIndex] = useState(0);
  const [imageIndex, setImageIndex] = useState(0);
  const [isWipeVisible, setIsWipeVisible] = useState(false);
  const location = useLocation();

  function showNextProject() {
    setIsWipeVisible(!isWipeVisible);

    setTimeout(() => {
      setProjectIndex((index) => {
        if (index === projects.length - 1) return 0;
        return index + 1;
      });

      setImageIndex(0);
    }, 1000);
  }

  function showPrevProject() {
    setIsWipeVisible(!isWipeVisible);

    setTimeout(() => {
      setProjectIndex((index) => {
        if (index === 0) return projects.length - 1;
        return index - 1;
      });

      setImageIndex(0);
    }, 1000);
  }

  function showNextImage() {
    setImageIndex((index) => {
      if (index === projects[projectIndex]?.pictures.length - 1) return 0;
      return index + 1;
    });
  }

  return (
    <div className="my-5 hidden md:block flex-col">
      {/* HEADERS */}
      <div className="my-10 flex justify-between items-start text-[#F5F5F5]">
        <div
          className={`flex items-center cursor-pointer hover:opacity-70 transition-transform duration-150 transform active:scale-125`}
          onClick={showPrevProject}
        >
          <LeftArrowButton />
          Prev
        </div>
        <motion.div
          className="font-normal text-xl md:text-xl w-[70%] flex"
          initial={{ x: 0 }}
          animate={{
            x: isWipeVisible ? 100 : 0,
            opacity: isWipeVisible ? 0 : 1,
          }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <div className="w-full">
            <p>{projects[projectIndex]?.name}</p>
            <p>{projects[projectIndex]?.year}</p>
          </div>
          <div>
            <p className="font-light">{projects[projectIndex]?.description}</p>
          </div>
        </motion.div>
        <div
          className={`flex items-center cursor-pointer hover:opacity-70 transition-transform duration-150 transform active:scale-125`}
          onClick={showNextProject}
        >
          Next
          <RightArrowButton />
        </div>
      </div>

      {/* SLIDER */}
      <div className="relative mx-16 h-[42rem] flex">
        <div className="h-full w-full flex overflow-hidden relative">
          {projects[projectIndex]?.pictures.length ? (
            projects[projectIndex]?.pictures.map((img, idx) => (
              <img
                key={idx}
                src={img}
                className="carousel-imgs"
                onClick={showNextImage}
                style={{ translate: `${-100 * imageIndex}%` }}
                alt={idx}
              />
            ))
          ) : (
            <iframe
              title={projects[projectIndex]?.name}
              className="h-full w-full"
              src={projects[projectIndex]?.video}
            ></iframe>
          )}
          <motion.div
            className="bg-[#2a2a2a] h-full w-0 absolute wipe-shape -top-1 z-20"
            initial={{ width: 0, height: 0 }}
            animate={{
              width: isWipeVisible ? "135%" : 0,
              height: isWipeVisible ? "385%" : 0,
            }}
            transition={{ duration: 1, ease: "easeInOut" }}
            onAnimationComplete={() => setIsWipeVisible(false)}
          />
        </div>

        <div className="carousel-dot-btn-container">
          {projects[projectIndex]?.pictures.length > 0 &&
            projects[projectIndex]?.pictures.map((_, idx) => (
              <button
                key={idx}
                className="carousel-dot-btn"
                onClick={() => setImageIndex(idx)}
                style={
                  idx === imageIndex
                    ? { backgroundColor: getPageColor(location.pathname) }
                    : {}
                }
              />
            ))}
        </div>
      </div>

      <motion.div
        className="mx-32 h-44 py-5 flex gap-3 justify-center"
        initial={{ x: 0 }}
        animate={{
          x: isWipeVisible ? 100 : 0,
          opacity: isWipeVisible ? 0 : 1,
        }}
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        {projects[projectIndex]?.pictures.length > 0 &&
          projects[projectIndex]?.pictures.map((img, idx) => (
            <div className="h-full w-1/6" key={idx}>
              <img
                className={`${
                  idx === imageIndex ? "border-[6px] border-transparent" : ""
                } carousel-display-imgs`}
                onClick={() => setImageIndex(idx)}
                key={idx}
                src={img}
                alt="Small project pics"
              />
            </div>
          ))}
      </motion.div>
    </div>
  );
}
