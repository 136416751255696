// import axios from "axios";

export function getBackground(location) {
  switch (location.pathname) {
    case "/about":
      return "bg-gradient-to-br from-white to-gray-200";
    default:
      return "bg-[#2A2A2A]";
  }
}

export function getNavBarTextColor(isActive, location) {
  switch (location) {
    case "/about":
      return "";
    case "/brand":
      return isActive
        ? "text-[#DC2647] font-medium after:content-['.']"
        : "text-[#F5F5F5]";
    case "/advertising":
      return isActive
        ? "text-[#1ECB1B] font-medium after:content-['.']"
        : "text-[#F5F5F5]";
    case "/ui+ux":
      return isActive
        ? "text-[#F3D11E] font-medium after:content-['.']"
        : "text-[#F5F5F5]";
    case "/creative":
      return isActive
        ? "text-[#9431E2] font-medium after:content-['.']"
        : "text-[#F5F5F5]";
    default:
      return "text-[#F5F5F5]";
  }
}

export function getPageColor(location) {
  switch (location) {
    case "/about":
      return "#1ECB1B";
    case "/brand":
      return "#DC2647";
    case "/advertising":
      return "#1ECB1B";
    case "/ui+ux":
      return "#F3D11E";
    case "/creative":
      return "#9431E2";
    default:
      return "#F5F5F5";
  }
}

// const apiKey = process.env.REACT_APP_API_KEY;
// const apiUrl = process.env.REACT_APP_API_URL;
// const headers = {
//   Authorization: `Bearer ${apiKey}`,
// };

// export async function getDatas() {
//   const res = await axios.get(`${apiUrl}/projects?populate=*`, { headers });
//   let categories = {};
//   res.data.data.forEach((p) => {
//     const categoryName =
//       p.attributes.category.data.attributes.title.toLowerCase();

//     if (!categories[categoryName]) {
//       categories[categoryName] = {
//         title: p.attributes.category.data.attributes.title,
//         description: p.attributes.category.data.attributes.description,
//         projects: [],
//       };
//     }

//     let pictures = [];
//     if (p.attributes.pictures.data && p.attributes.pictures.data.length > 0) {
//       pictures = p.attributes.pictures.data.map((pics) => pics.attributes.url);
//     }

//     categories[categoryName].projects.push({
//       title: p.attributes.name,
//       date: new Date(p.attributes.date).getFullYear(),
//       description: p.attributes.description,
//       pictures: pictures,
//     });
//   });
//   return categories;
// }
