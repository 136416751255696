import React from "react";
import { useLocation } from "react-router-dom";
import { getPageColor } from "../functions";

const RightArrowButton = () => {
  const location = useLocation();

  return (
    <svg
      width="35"
      height="44"
      viewBox="0 0 35 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0711403 43.5308L22.4012 43.5308L35 21.8088L22.4012 0.782008L0.071144 0.782006L12.496 21.8088L0.0711403 43.5308Z"
        fill={getPageColor(location.pathname)}
      />
    </svg>
  );
};

export default RightArrowButton;
