import { NavLink, useLocation } from "react-router-dom";
import { getNavBarTextColor } from "../functions";

const NavbarModal = ({ isToggled, categories }) => {
  const location = useLocation();
  return (
    <div
      className={`fixed h-full w-full z-10 font-freight text-3xl flex flex-col gap-3 p-5 modal-container ${
        location.pathname === "/about"
          ? "bg-gradient-to-br from-white to-gray-200"
          : "bg-[#2A2A2A]"
      } ${isToggled ? "visible" : ""}`}
    >
      {categories?.map((category) => (
        <NavLink
          to={category.name.toLowerCase()}
          key={category.name}
          className={({ isActive }) =>
            getNavBarTextColor(isActive, location.pathname)
          }
        >
          {category.name}
        </NavLink>
      ))}
      {/* <Link to="about">About</Link> */}
    </div>
  );
};

export default NavbarModal;
