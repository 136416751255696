import React from "react";
import { useLocation } from "react-router-dom";
import { getPageColor } from "../functions";

const AboutLogo = () => {
  const location = useLocation();

  return (
    <svg
      className="h-10"
      width="56"
      height="54"
      viewBox="0 0 56 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.2609 53.8255C44.0289 53.8255 56.0008 41.8537 56.0008 27.0856C56.0008 12.3176 44.0289 0.345703 29.2609 0.345703C14.4928 0.345703 2.52096 12.3176 2.52096 27.0856C2.52096 33.1517 4.54084 38.7459 7.9447 43.2325L0 53.8255H28.123L28.0942 53.8005C28.481 53.8172 28.87 53.8255 29.2609 53.8255Z"
        fill={getPageColor(location.pathname)}
      />
      {location.pathname === "/about" && (
        <path
          d="M27.194 15.9417H31.562V35.3457H27.194V27.1137H19.858V35.3457H15.49V15.9417H19.858V23.3617H27.194V15.9417ZM41.0645 35.3457H36.8925V20.6177H41.0645V35.3457ZM41.4565 16.3617C41.4565 17.127 41.2045 17.7337 40.7005 18.1817C40.2152 18.611 39.6365 18.8257 38.9645 18.8257C38.2925 18.8257 37.7045 18.611 37.2005 18.1817C36.7152 17.7337 36.4725 17.127 36.4725 16.3617C36.4725 15.5964 36.7152 14.999 37.2005 14.5697C37.7045 14.1217 38.2925 13.8977 38.9645 13.8977C39.6365 13.8977 40.2152 14.1217 40.7005 14.5697C41.2045 14.999 41.4565 15.5964 41.4565 16.3617Z"
          fill="#2A2A2A"
        />
      )}
    </svg>
  );
};

export default AboutLogo;
