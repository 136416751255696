import logoWhite from "../assets/images/logo-white.svg";
import logoBlack from "../assets/images/logo-black.svg";
import closeIconBlack from "../assets/images/close-icon-black.svg";
import burgerIconBlack from "../assets/images/menu-icon-black.svg";
import closeIcon from "../assets/images/close-icon-white.svg";
import burgerIcon from "../assets/images/menu-icon-white.svg";
import AboutLogo from "./AboutLogo";
import { NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

export default function MobileNavbar({ sendNavbarState }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  function test(location, type) {
    if (type === "close") {
      return location.pathname === "/about" ? closeIconBlack : closeIcon;
    } else {
      return location.pathname === "/about" ? burgerIconBlack : burgerIcon;
    }
  }

  useEffect(() => {
    if (isOpen) sendState();
    // eslint-disable-next-line
  }, [location]);

  const sendState = () => {
    sendNavbarState(!isOpen);
    setIsOpen(!isOpen);
  };

  return (
    <header className="md:hidden">
      <nav className="flex justify-between items-center px-8 h-[10dvh]">
        <motion.button whileTap={{ scale: 0.95 }} onClick={() => sendState()}>
          <motion.img
            src={isOpen ? test(location, "close") : test(location)}
            alt={isOpen ? "close-icon" : "burger-icon"}
            animate={{ rotate: isOpen ? 360 : 0 }}
            className="h-10"
            transition={{ duration: 1, type: "spring", stiffness: 100 }}
          />
        </motion.button>
        <NavLink to={"/"}>
          <img
            src={location.pathname === "/about" ? logoBlack : logoWhite}
            className="h-8"
            alt="logo"
          />
        </NavLink>
        <NavLink to={"/about"}>
          <AboutLogo />
        </NavLink>
      </nav>
    </header>
  );
}
