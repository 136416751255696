import { motion } from "framer-motion";
import MobileSlider from "../components/MobileSlider";
import Slider from "../components/Slider_v2";
import { useLocation } from "react-router-dom";
import { getPageColor } from "../functions";

export default function Category({ categories }) {
  const location = useLocation();
  let category;
  if (categories !== undefined) {
    category = categories.find((category) => {
      return category?.name.toLowerCase() === location.pathname.slice(1);
    });
  }

  return (
    <motion.div
      className="flex flex-col"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      // transition={{duration: 2}}
    >
      {category ? (
        <div className="md:container md:mx-auto">
          <div className="flex justify-center">
            <h1 className="md:w-[71%] font-freight font-medium text-4xl text-[#F5F5F5] md:text-7xl">
              {category?.name}
              <span className={`text-[${getPageColor(location.pathname)}]`}>
                .
              </span>
            </h1>
          </div>
          {/* <Slider projects={category?.projects} /> */}
          <Slider projects={category?.projects} />
          <MobileSlider projects={category?.projects} />
        </div>
      ) : (
        <div className="flex justify-center items-center h-[90dvh]">
          <h1 className="-mt-28 font-freight font-medium tracking-wider text-4xl md:text-7xl text-[#F5F5F5]">
            404 Not Found
          </h1>
        </div>
      )}
    </motion.div>
  );
}
