import React from "react";
import { useLocation } from "react-router-dom";
import { getPageColor } from "../functions";

const LeftArrowButton = () => {
  const location = useLocation();

  return (
    <svg
      width="35"
      height="44"
      viewBox="0 0 35 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.9289 0.782227H12.5988L0 22.5042L12.5988 43.531H34.9289L22.504 22.5042L34.9289 0.782227Z"
        fill={getPageColor(location.pathname)}
      />
    </svg>
  );
};

export default LeftArrowButton;
