import logoWhite from "../assets/images/logo-white.svg";
import logoBlack from "../assets/images/logo-black.svg";
import AboutLogo from "./AboutLogo";
import { getNavBarTextColor } from "../functions";
import { NavLink, useLocation } from "react-router-dom";
import React from "react";

export default function Navbar({ categories }) {
  const location = useLocation();

  return (
    <header className="hidden md:block container mx-auto">
      <nav className="flex items-center justify-between font-light text-2xl py-10 h-[10dvh]">
        <NavLink to={""}>
          <img
            src={location.pathname === "/about" ? logoBlack : logoWhite}
            className="h-10 text-black"
            alt="logo"
          />
        </NavLink>

        <div className="flex gap-10 lg:gap-32 ">
          {categories?.map((category) => (
            <NavLink
              key={category.name.toLowerCase()}
              to={category.name.toLowerCase()}
              className={({ isActive }) =>
                getNavBarTextColor(isActive, location.pathname)
              }
            >
              {category.name}
            </NavLink>
          ))}
        </div>

        <NavLink to={"/about"}>
          <AboutLogo />
        </NavLink>
      </nav>
    </header>
  );
}
