import Navbar from "./components/Navbar";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import { AnimatePresence } from "framer-motion";
import MobileNavbar from "./components/MobileNavbar";
import NavbarModal from "./components/NavbarModal";
import React, { useState, useEffect } from "react";
import { getBackground } from "./functions";
import Category from "./pages/Category";
import jsonDatas from "./assets/json/datas.json";

export default function App() {
  const [isNavbarToggled, setIsNavbarToggled] = useState(false);
  const [datas, setDatas] = useState(undefined);
  const location = useLocation();

  const handleNavbarToggle = (data) => {
    setIsNavbarToggled(data);
  };

  useEffect(() => {
    setDatas(jsonDatas);
  }, []);

  return (
    <>
      <section className={`${getBackground(location)}`}>
        <Navbar categories={datas?.categories} />
        <MobileNavbar sendNavbarState={handleNavbarToggle} />
        <NavbarModal
          categories={datas?.categories}
          isToggled={isNavbarToggled}
        />
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route index element={<Home />} />
            <Route
              path="/:name"
              element={<Category categories={datas?.categories} />}
            />
            <Route path="about" element={<About />} />
          </Routes>
        </AnimatePresence>
      </section>
    </>
  );
}
