import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { getPageColor } from "../functions";
import LeftArrowButton from "./LeftArrowButton";
import RightArrowButton from "./RightArrowButton";

export default function MobileSlider({ projects = [] }) {
  const [projectIndex, setProjectIndex] = useState(0);
  const [isWipeVisible, setIsWipeVisible] = useState(false);
  const [xValue, setXValue] = useState(100);
  const location = useLocation();

  function showNextProject() {
    setXValue(100);
    setIsWipeVisible(!isWipeVisible);

    setTimeout(() => {
      setProjectIndex((index) => {
        if (index === projects.length - 1) return 0;
        return index + 1;
      });
    }, 1000);
  }

  function showPrevProject() {
    setXValue(-100);
    setIsWipeVisible(!isWipeVisible);

    setTimeout(() => {
      setProjectIndex((index) => {
        if (index === 0) return projects.length - 1;
        return index - 1;
      });
    }, 1000);
  }

  return (
    <div className="md:hidden px-4 mt-6 text-[#F5F5F5]">
      <div className="flex justify-between items-center">
        <div
          onClick={showPrevProject}
          className="transition-transform duration-150 transform active:scale-125"
        >
          <LeftArrowButton />
        </div>
        <motion.h1
          className="font-freight font-medium leading-6	text-lg flex flex-col items-center"
          initial={{ x: 0 }}
          animate={{
            x: isWipeVisible ? xValue : 0,
            opacity: isWipeVisible ? 0 : 1,
          }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <p>{projects[projectIndex]?.name}</p>
          <p className={`text-[${getPageColor(location.pathname)}]`}>
            {projects[projectIndex]?.year}
          </p>
        </motion.h1>
        <div
          onClick={showNextProject}
          className="transition-transform duration-150 transform active:scale-125"
        >
          <RightArrowButton />
        </div>
      </div>

      <motion.p
        className="font-light	text-lg p-4"
        initial={{ x: 0 }}
        animate={{
          x: isWipeVisible ? xValue : 0,
          opacity: isWipeVisible ? 0 : 1,
        }}
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        {projects[projectIndex]?.description}
      </motion.p>

      <motion.div
        className="flex flex-col gap-3 pb-4"
        initial={{ x: 0 }}
        animate={{
          x: isWipeVisible ? xValue : 0,
          opacity: isWipeVisible ? 0 : 1,
        }}
        transition={{ duration: 1, ease: "easeInOut" }}
        onAnimationComplete={() => setIsWipeVisible(false)}
      >
        {projects[projectIndex]?.pictures.length ? (
          projects[projectIndex]?.pictures.map((img, idx) => (
            <div className="h-[25vh] w-100" key={idx}>
              <img
                className="!object-fill carousel-display-imgs rounded-xl"
                key={idx}
                src={img}
                alt="Project Images"
              />
            </div>
          ))
        ) : (
          <iframe
            title={projects[projectIndex]?.name}
            className="h-[40vh] w-100"
            src={projects[projectIndex]?.video}
          ></iframe>
        )}
      </motion.div>
    </div>
  );
}
